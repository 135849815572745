import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _91ad932c = () => interopDefault(import('../client/pages/cart/index.vue' /* webpackChunkName: "" */))
const _7673dce6 = () => interopDefault(import('../client/pages/products/no-minimum/_.vue' /* webpackChunkName: "" */))
const _9511698c = () => interopDefault(import('../client/pages/products/closeout/_.vue' /* webpackChunkName: "" */))
const _a024030e = () => interopDefault(import('../client/pages/account/index.vue' /* webpackChunkName: "pages/account/index" */))
const _7643e0ea = () => interopDefault(import('../client/pages/cartitemsadded/index.vue' /* webpackChunkName: "pages/cartitemsadded/index" */))
const _6c7e1dd6 = () => interopDefault(import('../client/pages/changepassword/index.vue' /* webpackChunkName: "pages/changepassword/index" */))
const _52f525f4 = () => interopDefault(import('../client/pages/customer-artwork-proof/index.vue' /* webpackChunkName: "pages/customer-artwork-proof/index" */))
const _178430c1 = () => interopDefault(import('../client/pages/designs/index.vue' /* webpackChunkName: "pages/designs/index" */))
const _e2d17bb4 = () => interopDefault(import('../client/pages/facets/index.vue' /* webpackChunkName: "pages/facets/index" */))
const _f086cc3a = () => interopDefault(import('../client/pages/favorites/index.vue' /* webpackChunkName: "pages/favorites/index" */))
const _b4442c70 = () => interopDefault(import('../client/pages/forgotpassword/index.vue' /* webpackChunkName: "pages/forgotpassword/index" */))
const _f5205aba = () => interopDefault(import('../client/pages/health.vue' /* webpackChunkName: "pages/health" */))
const _7b603254 = () => interopDefault(import('../client/pages/not-found/index.vue' /* webpackChunkName: "pages/not-found/index" */))
const _486f3510 = () => interopDefault(import('../client/pages/proof/index.vue' /* webpackChunkName: "pages/proof/index" */))
const _6af04810 = () => interopDefault(import('../client/pages/proofitemsadded/index.vue' /* webpackChunkName: "pages/proofitemsadded/index" */))
const _7728f148 = () => interopDefault(import('../client/pages/quoteitemsadded/index.vue' /* webpackChunkName: "pages/quoteitemsadded/index" */))
const _b8252794 = () => interopDefault(import('../client/pages/resetpassword/index.vue' /* webpackChunkName: "pages/resetpassword/index" */))
const _78fada98 = () => interopDefault(import('../client/pages/sampleitemsadded/index.vue' /* webpackChunkName: "pages/sampleitemsadded/index" */))
const _7ed3a0ac = () => interopDefault(import('../client/pages/signin/index.vue' /* webpackChunkName: "pages/signin/index" */))
const _6cb7e886 = () => interopDefault(import('../client/pages/signout/index.vue' /* webpackChunkName: "pages/signout/index" */))
const _bcd9289a = () => interopDefault(import('../client/pages/suppliers/index.vue' /* webpackChunkName: "pages/suppliers/index" */))
const _25173a33 = () => interopDefault(import('../client/pages/testy/index.vue' /* webpackChunkName: "pages/testy/index" */))
const _739b24be = () => interopDefault(import('../client/pages/version.vue' /* webpackChunkName: "pages/version" */))
const _acacbe90 = () => interopDefault(import('../client/pages/version-app/index.vue' /* webpackChunkName: "pages/version-app/index" */))
const _eae41a28 = () => interopDefault(import('../client/pages/cat/GeneralProductView.vue' /* webpackChunkName: "pages/cat/GeneralProductView" */))
const _74ddff5a = () => interopDefault(import('../client/pages/orders/products/index.vue' /* webpackChunkName: "pages/orders/products/index" */))
const _4662a162 = () => interopDefault(import('../client/pages/products/utils.js' /* webpackChunkName: "pages/products/utils" */))
const _7282ce5d = () => interopDefault(import('../client/pages/sso/facebook/delete.vue' /* webpackChunkName: "pages/sso/facebook/delete" */))
const _48625a82 = () => interopDefault(import('../client/pages/review/order/_orderId/product/_productId.vue' /* webpackChunkName: "pages/review/order/_orderId/product/_productId" */))
const _4e79fc0c = () => interopDefault(import('../client/pages/products/search/_.vue' /* webpackChunkName: "pages/products/search/_" */))
const _c1e2f400 = () => interopDefault(import('../client/pages/blog/cat/_.vue' /* webpackChunkName: "pages/blog/cat/_" */))
const _ee86043c = () => interopDefault(import('../client/pages/approval/_orderId/index.vue' /* webpackChunkName: "pages/approval/_orderId/index" */))
const _f8e8ded6 = () => interopDefault(import('../client/pages/artproof/_productSlug/index.vue' /* webpackChunkName: "pages/artproof/_productSlug/index" */))
const _789f569a = () => interopDefault(import('../client/pages/authors/_authorName/index.vue' /* webpackChunkName: "pages/authors/_authorName/index" */))
const _ad6a58c4 = () => interopDefault(import('../client/pages/components/_componentId/index.vue' /* webpackChunkName: "pages/components/_componentId/index" */))
const _59bc500d = () => interopDefault(import('../client/pages/configureproduct/_productSlug/index.vue' /* webpackChunkName: "pages/configureproduct/_productSlug/index" */))
const _f996d61e = () => interopDefault(import('../client/pages/customer-artwork-proof/_id/index.vue' /* webpackChunkName: "pages/customer-artwork-proof/_id/index" */))
const _2854bf2b = () => interopDefault(import('../client/pages/designer/_productSlug/index.vue' /* webpackChunkName: "pages/designer/_productSlug/index" */))
const _77c4d73c = () => interopDefault(import('../client/pages/designs/_slug/index.vue' /* webpackChunkName: "pages/designs/_slug/index" */))
const _3d5c88e1 = () => interopDefault(import('../client/pages/facets/_slug/index.vue' /* webpackChunkName: "pages/facets/_slug/index" */))
const _0a53a0f5 = () => interopDefault(import('../client/pages/largequote/_productSlug/index.vue' /* webpackChunkName: "pages/largequote/_productSlug/index" */))
const _5486079a = () => interopDefault(import('../client/pages/orders/_id/index.vue' /* webpackChunkName: "pages/orders/_id/index" */))
const _a5d56e90 = () => interopDefault(import('../client/pages/payments/_orderId/index.vue' /* webpackChunkName: "pages/payments/_orderId/index" */))
const _5339ef2b = () => interopDefault(import('../client/pages/presentation/_orderId/index.vue' /* webpackChunkName: "pages/presentation/_orderId/index" */))
const _e168ace0 = () => interopDefault(import('../client/pages/presentations/_id/index.vue' /* webpackChunkName: "pages/presentations/_id/index" */))
const _09deceb6 = () => interopDefault(import('../client/pages/printinvoice/_orderId/index.vue' /* webpackChunkName: "pages/printinvoice/_orderId/index" */))
const _8c8d0e2e = () => interopDefault(import('../client/pages/products/_slug/index.vue' /* webpackChunkName: "pages/products/_slug/index" */))
const _48f1700a = () => interopDefault(import('../client/pages/proof/_id/index.vue' /* webpackChunkName: "pages/proof/_id/index" */))
const _5c4ceca7 = () => interopDefault(import('../client/pages/quickquote/_productSlug/index.vue' /* webpackChunkName: "pages/quickquote/_productSlug/index" */))
const _04400b32 = () => interopDefault(import('../client/pages/reorder/_id/index.vue' /* webpackChunkName: "pages/reorder/_id/index" */))
const _c1549c68 = () => interopDefault(import('../client/pages/sample/_productSlug/index.vue' /* webpackChunkName: "pages/sample/_productSlug/index" */))
const _0ad53506 = () => interopDefault(import('../client/pages/sso/_service.vue' /* webpackChunkName: "pages/sso/_service" */))
const _4dd1bf52 = () => interopDefault(import('../client/pages/cat/_slug/brand.vue' /* webpackChunkName: "pages/cat/_slug/brand" */))
const _3de7be72 = () => interopDefault(import('../client/pages/orders/_id/track-shipment/index.vue' /* webpackChunkName: "pages/orders/_id/track-shipment/index" */))
const _7239d3f2 = () => interopDefault(import('../client/pages/products/_slug/reviews.vue' /* webpackChunkName: "pages/products/_slug/reviews" */))
const _f93f2108 = () => interopDefault(import('../client/pages/facets/_slug/_facet/index.vue' /* webpackChunkName: "pages/facets/_slug/_facet/index" */))
const _a5c9297a = () => interopDefault(import('../client/pages/supplier/_psCode/_.vue' /* webpackChunkName: "pages/supplier/_psCode/_" */))
const _1b1999aa = () => interopDefault(import('../client/pages/cat/_slug/_.vue' /* webpackChunkName: "pages/cat/_slug/_" */))
const _3ea42330 = () => interopDefault(import('../client/pages/_.vue' /* webpackChunkName: "pages/_" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/box/cart",
    component: _91ad932c,
    name: "box/cart"
  }, {
    path: "/quote/cart",
    component: _91ad932c,
    name: "quote/cart"
  }, {
    path: "/sample/cart",
    component: _91ad932c,
    name: "sample/cart"
  }, {
    path: "/proof/cart",
    component: _91ad932c,
    name: "proof/cart"
  }, {
    path: "/boxcart",
    component: _91ad932c,
    name: "boxcart"
  }, {
    path: "/quotecart",
    component: _91ad932c,
    name: "quotecart"
  }, {
    path: "/samplecart",
    component: _91ad932c,
    name: "samplecart"
  }, {
    path: "/proofcart",
    component: _91ad932c,
    name: "proofcart"
  }, {
    path: "/products/no-minimum",
    component: _7673dce6,
    name: "products-no-minimum"
  }, {
    path: "/products/closeout",
    component: _9511698c,
    name: "products-closeout"
  }, {
    path: "/account",
    component: _a024030e,
    name: "account"
  }, {
    path: "/cart",
    component: _91ad932c,
    name: "cart"
  }, {
    path: "/cartitemsadded",
    component: _7643e0ea,
    name: "cartitemsadded"
  }, {
    path: "/changepassword",
    component: _6c7e1dd6,
    name: "changepassword"
  }, {
    path: "/customer-artwork-proof",
    component: _52f525f4,
    name: "customer-artwork-proof"
  }, {
    path: "/designs",
    component: _178430c1,
    name: "designs"
  }, {
    path: "/facets",
    component: _e2d17bb4,
    name: "facets"
  }, {
    path: "/favorites",
    component: _f086cc3a,
    name: "favorites"
  }, {
    path: "/forgotpassword",
    component: _b4442c70,
    name: "forgotpassword"
  }, {
    path: "/health",
    component: _f5205aba,
    name: "health"
  }, {
    path: "/not-found",
    component: _7b603254,
    name: "not-found"
  }, {
    path: "/proof",
    component: _486f3510,
    name: "proof"
  }, {
    path: "/proofitemsadded",
    component: _6af04810,
    name: "proofitemsadded"
  }, {
    path: "/quoteitemsadded",
    component: _7728f148,
    name: "quoteitemsadded"
  }, {
    path: "/resetpassword",
    component: _b8252794,
    name: "resetpassword"
  }, {
    path: "/sampleitemsadded",
    component: _78fada98,
    name: "sampleitemsadded"
  }, {
    path: "/signin",
    component: _7ed3a0ac,
    name: "signin"
  }, {
    path: "/signout",
    component: _6cb7e886,
    name: "signout"
  }, {
    path: "/suppliers",
    component: _bcd9289a,
    name: "suppliers"
  }, {
    path: "/testy",
    component: _25173a33,
    name: "testy"
  }, {
    path: "/version",
    component: _739b24be,
    name: "version"
  }, {
    path: "/version-app",
    component: _acacbe90,
    name: "version-app"
  }, {
    path: "/cat/GeneralProductView",
    component: _eae41a28,
    name: "cat-GeneralProductView"
  }, {
    path: "/orders/products",
    component: _74ddff5a,
    name: "orders-products"
  }, {
    path: "/products/utils",
    component: _4662a162,
    name: "products-utils"
  }, {
    path: "/sso/facebook/delete",
    component: _7282ce5d,
    name: "sso-facebook-delete"
  }, {
    path: "/review/order/:orderId?/product/:productId?",
    component: _48625a82,
    name: "review-order-orderId-product-productId"
  }, {
    path: "/products/search/*",
    component: _4e79fc0c,
    name: "products-search-all"
  }, {
    path: "/products/no-minimum/*",
    component: _7673dce6,
    name: "products-no-minimum-all"
  }, {
    path: "/products/closeout/*",
    component: _9511698c,
    name: "products-closeout-all"
  }, {
    path: "/blog/cat/*",
    component: _c1e2f400,
    name: "blog-cat-all"
  }, {
    path: "/approval/:orderId",
    component: _ee86043c,
    name: "approval-orderId"
  }, {
    path: "/artproof/:productSlug",
    component: _f8e8ded6,
    name: "artproof-productSlug"
  }, {
    path: "/authors/:authorName",
    component: _789f569a,
    name: "authors-authorName"
  }, {
    path: "/components/:componentId",
    component: _ad6a58c4,
    name: "components-componentId"
  }, {
    path: "/configureproduct/:productSlug",
    component: _59bc500d,
    name: "configureproduct-productSlug"
  }, {
    path: "/customer-artwork-proof/:id",
    component: _f996d61e,
    name: "customer-artwork-proof-id"
  }, {
    path: "/designer/:productSlug",
    component: _2854bf2b,
    name: "designer-productSlug"
  }, {
    path: "/designs/:slug",
    component: _77c4d73c,
    name: "designs-slug"
  }, {
    path: "/facets/:slug",
    component: _3d5c88e1,
    name: "facets-slug"
  }, {
    path: "/largequote/:productSlug",
    component: _0a53a0f5,
    name: "largequote-productSlug"
  }, {
    path: "/orders/:id",
    component: _5486079a,
    name: "orders-id"
  }, {
    path: "/payments/:orderId",
    component: _a5d56e90,
    name: "payments-orderId"
  }, {
    path: "/presentation/:orderId",
    component: _5339ef2b,
    name: "presentation-orderId"
  }, {
    path: "/presentations/:id",
    component: _e168ace0,
    name: "presentations-id"
  }, {
    path: "/printinvoice/:orderId",
    component: _09deceb6,
    name: "printinvoice-orderId"
  }, {
    path: "/products/:slug",
    component: _8c8d0e2e,
    name: "products-slug"
  }, {
    path: "/proof/:id",
    component: _48f1700a,
    name: "proof-id"
  }, {
    path: "/quickquote/:productSlug",
    component: _5c4ceca7,
    name: "quickquote-productSlug"
  }, {
    path: "/reorder/:id",
    component: _04400b32,
    name: "reorder-id"
  }, {
    path: "/sample/:productSlug",
    component: _c1549c68,
    name: "sample-productSlug"
  }, {
    path: "/sso/:service?",
    component: _0ad53506,
    name: "sso-service"
  }, {
    path: "/cat/:slug?/brand",
    component: _4dd1bf52,
    name: "cat-slug-brand"
  }, {
    path: "/orders/:id?/track-shipment",
    component: _3de7be72,
    name: "orders-id-track-shipment"
  }, {
    path: "/products/:slug?/reviews",
    component: _7239d3f2,
    name: "products-slug-reviews"
  }, {
    path: "/facets/:slug/:facet",
    component: _f93f2108,
    name: "facets-slug-facet"
  }, {
    path: "/supplier/:psCode?/*",
    component: _a5c9297a,
    name: "supplier-psCode-all"
  }, {
    path: "/cat/:slug?/*",
    component: _1b1999aa,
    name: "cat-slug-all"
  }, {
    path: "/*",
    component: _3ea42330,
    name: "all"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
